import { Injectable } from "@angular/core";
import { createStore, select, setProp, withProps } from "@ngneat/elf";
import { selectFirst, setEntities, withEntities } from "@ngneat/elf-entities";

export type NotificationForm = {
    title: string,
    description: string,
    category: 'information' | 'warning' | 'error',
    from: Date,
    to: Date | undefined,
    now: boolean,
    unlimited: boolean,
    persistent: boolean,
    tenants: string[]
}

export const emptyNotificationForm = {
    category: 'information',
    title: '',
    description: '',
    from: new Date(),
    to: undefined,
    now: false,
    unlimited: true,
    persistent: false,
    tenants: []
} as NotificationForm;

const manageNotificationsStore = createStore(
    { name: 'form'},
    withEntities<NotificationForm, 'title'>({
        idKey: 'title',
        initialValue: [emptyNotificationForm]
    }),
    withProps<{
        search: string,
        collapsed: boolean
    }>({
        search: '',
        collapsed: false
    })
);

@Injectable({ providedIn: 'root' })
export class notificationsRepository {

    // notification data in form
    form$ = manageNotificationsStore.pipe(
        selectFirst(),
    )

    // free text search query
    search$ = manageNotificationsStore.pipe(
        select((state) => state.search)
    )

    // whether panel is collapsed
    collapsed$ = manageNotificationsStore.pipe(
        select((state) => state.collapsed)
    )

    // update notification form
    updateForm(form: NotificationForm) {
        manageNotificationsStore.update(setEntities([form]))
    }
    
    // update search query term
    updateSearch(text: string) {
        manageNotificationsStore.update(setProp('search', text))
    }

    // set the state of the panel collapsible
    setCollapsed(collapsed: boolean) {
        manageNotificationsStore.update(setProp('collapsed', collapsed))
    }
}
