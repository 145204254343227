import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SuccessRequestResult } from '@ngneat/elf-requests/src/lib/requests-result';
import { map, Observable, Subject } from 'rxjs';
import { EnumeratedState, StateHelperService } from 'src/app/core/helpers/state-helper.service';
import { StationLocations } from 'src/app/core/data-backend/models';
import { OverviewCacheService } from '../core/app-services/overview-cache.service';
import { appRepository } from 'src/app/core/stores/app.repository';
import { mapRepository } from 'src/app/core/stores/map.repository';
import { PlotLayout } from 'src/app/core/plots/plot.models';
import { PlotTooltipsService } from 'src/app/core/plots/plot-tooltips.service';
import { overviewRepository } from '../core/stores/overview.repository';
import { GlobalService } from '../core/app-services/global.service';
import { stationFiltersRepository } from '../core/stores/station-filters.repository';
import { TranslateService } from '@ngx-translate/core';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'app-map-view',
    template: `
        <div class="map-header-wrapper container">
            <app-station-filters
                [view]="'map'"
                [updatedAt]="mapUpdatedAt$ | async"
            ></app-station-filters>
        </div>
        <div class="map-wrapper">
            <app-stations-map
                [stationLocations]="(overviewRepository.stationLocations$ | async)?.data || null"
                [isLoading]="(overviewRepository.stationLocations$ | async)?.isLoading ?? false"
                [showSatelliteAt]="15"
                [fullscreen]="globalService.isFullscreen$ | async"
                [interactionMode]="'popup-analytics'"
                [mapSettings]="{
                    padding: {
                        top: 3,
                        right: 1,
                        bottom: 1,
                        left: 1
                    },
                    hideIconsHeight: null,
                    logoPosition: 'bottom-left'
                }"
            >
            </app-stations-map>
        </div>
        <div class="map-graph-wrapper container">
            <div class="position-relative">
                <div
                    *ngIf="mapRepository.showGraphs$ | async"
                    [@inOutAnimation]
                >
                    <div class="no-coords-wrapper">
                        <div class="no-coords">
                            {{ 'MAP_VIEW.STATIONS_NO_COORDS' | translate }} {{ (noCoords$ | async) | number }}
                        </div>
                    </div>
                    <div class="plot-wrapper" #wrapper>
                        <div class="plot-header">
                            <div class="plot-title">{{ 'MAP_VIEW.STATION_STATES' | translate }}</div>
                            <div class="plot-subtitle">{{ 'MAP_VIEW.AT_THE_MOMENT' | translate }}</div>
                        </div>
                        <plotly-plot
                            [data]="plotData$ | async"
                            [layout]="plotLayout"
                            [config]="plotConfig"
                            [updateOnDataChange]="true"
                            [style]="{display: 'flex', alignItems: 'center', justifyContent: 'center'}"
                            [useResizeHandler]="false"
                            (plotlyClick)="setFilterOnClick($event, 'lastOverallState', 'label')"
                            (hover)="plotTooltips.renderTooltip($event, wrapper)"
                            (unhover)="plotTooltips.removeTooltip()"
                        ></plotly-plot>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./map-view.component.scss'],
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(50px)' }),
                animate('.25s ease-out', 
                style({ opacity: 1, transform: 'translateY(0px)' }))
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateY(0px)' }),
                animate('.25s ease-out', 
                style({ opacity: 0, transform: 'translateY(50px)' }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapViewComponent implements OnInit, OnDestroy {
    // data for charging station plot
    private readonly _destroying$ = new Subject<void>();
    public modalOpen: boolean = false;
    public mapUpdatedAt$: Observable<Date | null>;
    // color states
    private _stateHelper = new StateHelperService()
    private _stateColors  = this._stateHelper.getStateColors()
    // disable opening of plot when no data available
    public disablePlot: boolean = false;

    public plotData$: Observable<any | null>;
    public plotLayout: PlotLayout = {
        width: 310,
        height: 250,
        margin: { t: 5, b: 20, l: 30, r: 20, autoexpand: true },
        font: {
            family: '"EONBrixSans", Arial, Geneva, Helvetica, sans-serif'
        },
        xaxis: {
            showticklabels: false,
            fixedrange: true
        },
        yaxis: {
            color: '#DBEAFE',
            gridcolor: '#DBEAFE',
            fixedrange: true
        },
        showlegend: false
    };
    public plotConfig: any = {
        displayModeBar: false
    }

    // amount of stations without coordinates
    public noCoords$: Observable<number>;

    constructor(
        public appRepository: appRepository,
        public mapRepository: mapRepository,
        public overviewRepository: overviewRepository,
        public overviewCacheService: OverviewCacheService,
        public plotTooltips: PlotTooltipsService,
        public globalService: GlobalService,
        public translate: TranslateService,
        private _filtersRepo: stationFiltersRepository
    ) {
        // get latest update Date from elf states
        this.mapUpdatedAt$ = overviewRepository.stationLocations$.pipe(
            map((req) => {
                const updatedAt = (req as SuccessRequestResult).dataUpdatedAt;
                return updatedAt ? new Date(updatedAt) : null;
            })
        )

        this.plotData$ = overviewRepository.stationLocations$.pipe(
            map((req) => {
                let data : StationLocations[];
                data = req.data.stationLocations;

                let plotData: {[key in EnumeratedState as string]: number} = {
                    'Ok': 0,
                    'To Be Monitored': 0,
                    'Potential Failure': 0,
                    'Failure': 0,
                    'No Data': 0
                }
    
                // find 'worst' state of entrys lastOverallStates
                data.forEach(entry => {
                    let [worstState, color] = this._stateHelper.getLowestEnumeratedStateInArray(entry.lastOverallState as EnumeratedState[]);
                    plotData[worstState || 'No Data']++
                })
        
                let barTrace =  [{
                    type: "bar",
                    y: Object.values(plotData),
                    x: Object.keys(plotData),
                    width: 0.5,
                    text: Object.values(plotData).map((value) => {
                        // round everything > 1k
                        return value > 1_000
                            ? (value / 1_000).toFixed(1) + 'k'
                            : formatNumber(value, this.translate.currentLang);
                    }),
                    hoverinfo: "none",
                    textinfo: "none",
                    marker: {
                        color: [
                            this._stateColors.ok, 
                            this._stateColors.toBeMonitored, 
                            this._stateColors.potentialFailure, 
                            this._stateColors.failure, 
                            this._stateColors.noData
                        ],
                    },
                    customdata: {
                        plotType: 'charging-stations'
                    }
                }];
        
                // update data
                return barTrace;
            })
        );

        this.noCoords$ = overviewRepository.stationLocations$.pipe(
            map((req) => {
                let data : StationLocations[];
                data = req.data.stationLocations;
                const noCoords = data.filter((station) => !station.latitude || !station.longitude);
                return noCoords.length;
            })
        )
    }

    // store latest filter exceptions and remove them while looking at the operation map
    // last state will be reset in onDestroy
    private _lastFilterExceptions: any[] = [];
    ngOnInit(): void {
        this._lastFilterExceptions = this._filtersRepo.getFilterExceptions();
        this._filtersRepo.setFilterExceptions([]);
    }

    // adds active filter based on selection in plot
    // callable with attribute to filter and scope of where to get the filterValue from the event
    setFilterOnClick(event: any, filterAttr: string, scope: string) {
        let value = event['points'][0][scope];
        if (value === undefined || (typeof(value) !== 'string' && typeof(value) !== 'number')) return

        if (this._filtersRepo.hasFilter(filterAttr)) {
            let currentFilter = this._filtersRepo.getFilterValue(filterAttr);
            // update if set filter doesnt match new
            if (
                currentFilter && 
                currentFilter.value && 
                currentFilter.value.length === 1 && 
                currentFilter.value[0] !== value
            ) {
                this._filtersRepo.updateFilterValue(filterAttr, [value])
            } else {
                this._filtersRepo.deleteFilters(filterAttr)
            }
        } else {
            this._filtersRepo.addFilter(filterAttr, [value])
        }
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this._filtersRepo.setFilterExceptions(this._lastFilterExceptions);
    }
}
