import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/core/app-services/global.service';
import { Customer } from 'src/app/core/auth-backend/models';
import { appRepository } from 'src/app/core/stores/app.repository';
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-login-screen',
    template: `
    <div class="background">
        <div class="icon-wrapper flex-row justify-content-center">
            <img class="icon" [src]="'../assets/logos/' + this.brandName + '-icon.svg'" [alt]="this.brandName">
        </div>

        <div class="window-wrapper flex-row justify-content-center">
            <div 
                *ngIf="!isLoggedIn"
                class="login-window"
            >
                <img [src]="'../assets/logos/' + this.brandName + '.svg'" [alt]="this.brandName">
            </div>
            <ng-container *ngIf="isLoggedIn">
                <div 
                    class="login-window" 
                    [@inOutAnimation]
                >
                    <ng-container
                        *ngIf="!isCustomerSelected && selectOptions.length > 0; else loading"
                    >
                        <app-select-single
                            class="w-100"
                            [title]="'LOGIN_VIEW.SELECT_CUSTOMER' | translate"
                            [chevron]="true"
                            [options]="selectOptions"
                            [activeSelection]="selectedCustomer?.identifier ?? null"
                            (activeSelectionChange)="storeOption($event)"
                        />
                        <br>
                        <button 
                            class="default-button wider"
                            (click)="this.customerSelected.emit(this.selectedCustomer)"
                        >{{ 'LOGIN_VIEW.SELECT_CONTENT' | translate: {content: (selectedCustomer?.name || selectedCustomer?.identifier)} }}</button>
                    </ng-container>
                </div>
            </ng-container>

            <ng-template #loading>
                <app-preloader></app-preloader>
                <br><br><br>
                <a 
                    class="logout-hint"
                    (click)="logoutClicked.emit(true)"
                >{{ 'NAVIGATION.LOGOUT' | translate }}</a>
            </ng-template>

            <div class="footer">
                <a (click)="globalService.toggleImprint()">{{ 'IMPRINT.TITLE' | translate }}</a>
                <a (click)="globalService.togglePrivacy()">{{ 'PRIVACY_POLICY.TITLE' | translate }}</a>
            </div>
        </div>
    </div>
  `,
    styleUrls: ['./login-screen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateX(-50px)' }),
                animate('.15s ease-out',
                    style({ opacity: 1, transform: 'translateX(0px)' }))
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'translateX(0px)' }),
                animate('.15s ease-in',
                    style({ opacity: 0, transform: 'translateX(50px)' }))
            ])
        ])
    ]
})
export class LoginScreenComponent {
    @Input('isLoggedIn') isLoggedIn: boolean | null = false;
    @Input('isCustomerSelected') isCustomerSelected: boolean | null = false;
    @Input('isUserSet') isUserSet: boolean | null = false;

    public availableCustomers: Customer[] | undefined;
    public selectOptions: { label: string, value: string }[] = [];
    @Input('customers') set customers(customers: Customer[]) {
        customers = structuredClone(customers);
        this.availableCustomers = customers
        this.selectedCustomer = customers[0];
        // create select options
        this.selectOptions = this.availableCustomers.map((customer) => {
            return {
                label: customer.name ?? customer.identifier,
                value: customer.identifier
            }
        })
    };

    selectedCustomer: Customer | undefined;

    @Output('logoutClicked') logoutClicked = new EventEmitter<boolean>();
    @Output('customerSelected') customerSelected = new EventEmitter<Customer>();

    readonly brandName: string = environment.brandName;

    constructor(
        public globalService: GlobalService,
        public appRepo: appRepository,
        public router: Router
    ) { }

    storeOption(customerId: string | number) {
        // select-single component will return undefined when first option is selected
        // that's because the first option would usally be used to reset selection
        const customer = this.availableCustomers?.find((x) => x.identifier == customerId);
        if (customerId !== undefined) {
            this.selectedCustomer = customer
        } else {
            this.selectedCustomer = this.availableCustomers?.[0]
        }
    }
}
