import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { StateHelperService } from '../helpers/state-helper.service';
import { DateRange, PlotConfig, PlotLayout, PlotState, PlotType } from './plot.models';

interface KpiPlotConfig extends PlotConfig {
    tickformat?: string,
    yaxisrange?: number[]
}

@Injectable({
    providedIn: 'root'
})
export class KpiPlotsService {
    private _dataToDisplay$:    
              BehaviorSubject<{ date: string; value: number; }[] | null>      
        = new BehaviorSubject<{ date: string; value: number; }[] | null>(null);
    private _tooltipData$:    
              BehaviorSubject<{ date: string; value: number; }[] | null | undefined>      
        = new BehaviorSubject<{ date: string; value: number; }[] | null | undefined >(null);
    private _stateHelper:       StateHelperService                                  = new StateHelperService();
    private _plotType$:         BehaviorSubject<PlotType | null>                    = new BehaviorSubject<PlotType | null>(null);
    private _dateRange$:        BehaviorSubject<DateRange | null>                   = new BehaviorSubject<DateRange | null>(null);
    private _interval$:         BehaviorSubject<number | null>                      = new BehaviorSubject<number | null>(null);
    private _plotData$:         BehaviorSubject<any[]>                              = new BehaviorSubject<any[]>([]);
    private _plotState$:        BehaviorSubject<PlotState>                          = new BehaviorSubject<PlotState>('inactive');
    private _plotRevision$:     BehaviorSubject<number>                             = new BehaviorSubject<number>(0);
    private _mappedAxes:        {x: string, y: string}                              = {x: 'x', y: 'y'}
    private _fixedRange:        {x: boolean, y: boolean}                            = {x: false, y: false};
    private _tickformat:        string                                              = ''
    private _yaxisRange:        number[]                                            = []
    private _layout$:           BehaviorSubject<PlotLayout>                         = new BehaviorSubject<PlotLayout>({
        margin: { t: 10, b: 55, l: 55, r: 5 },
        pad: {l: 20},
        height: 380,
        font: {
            family: '"EONBrixSans", Arial, Geneva, Helvetica, sans-serif'
        },
        yaxis: {
            showline: false,
            zeroline: false,
            gridcolor: this._stateHelper.getVar('--kpi-text-15'),
            ticksuffix: '   ',
            fixedrange: true,
            rangemode: 'tozero'
        },
        xaxis: {
            type: 'date',
            showline: false,
            showgrid: false,
            showspikes: true,
            spikedash: 'solid',
            spikecolor: this._stateHelper.getVar('--kpi-primary'),
            spikethickness: 2,
            automargin: true,
            tickmode: 'auto',
            tick0: 0,
            tickformat: '%d.%m.',
            tickangle: -90,
            nticks: 20,
        }
    });
    private _scatterOptions: any = {};
    public plotZoomX: DateRange | null = null;
    public plotConfig: object = {
        displayModeBar: false, 
        scrollZoom: false,
        doubleClick: false,
        showTips: false
    };

    constructor(
        @Inject('plotType') plotType: PlotType,
        @Inject('dateRange') dateRange: DateRange,
        @Inject('interval') interval: number,
        @Inject('dataToDisplay') dataToDisplay: { date: string; value: number; }[] | null,
        @Inject('config') config?: KpiPlotConfig,
        @Inject('tooltipData') tooltipData?: { date: string; value: number; }[] | null
    ) {
        this._plotType$.next(plotType)
        this._dateRange$.next(dateRange)
        this._interval$.next(interval)
        this._dataToDisplay$.next(dataToDisplay)
        this._tooltipData$.next(tooltipData)

        if (config) {
            if (config.xaxis)           this._mappedAxes.x  = config.xaxis
            if (config.yaxis)           this._mappedAxes.y  = config.yaxis
            if (config.fixedX)          this._fixedRange.x  = config.fixedX
            if (config.fixedY)          this._fixedRange.y  = config.fixedY
            if (config.tickformat)      this._tickformat    = config.tickformat
            if (config.yaxisrange)      this._yaxisRange    = config.yaxisrange
        }
        this._scatterOptions = {
            xaxis: this._mappedAxes.x,
            yaxis: this._mappedAxes.y,
            name: this._plotType$.getValue(),
            type: 'scatter',
            mode: 'lines',
            fill: 'tozeroy',
            hoverinfo: 'none',
            line: {
                shape: 'linear'
            },
            marker: {
                color: this._stateHelper.getVar('--kpi-secondary')
            },
            customdata: {
                plotType: this._plotType$.getValue()
            }
        }

        this._updatePlot()
    }

    set dateRange(data: DateRange) {
        this._dateRange$.next(data)
        this._updatePlot()
    }

    set interval(data: number) {
        this._interval$.next(data)
        this._updatePlot()
    }

    set dataToDisplay(data: { date: string; value: number; }[] | null) {
        this._dataToDisplay$.next(data)
        this._updatePlot()
    }

    set tooltipData(data: { date: string; value: number; }[] | null) {
        this._tooltipData$.next(data)
    }

    set plotType(data: PlotType) {
        this._plotType$.next(data)
    }

    get layout$() {
        return this._layout$.asObservable()
    }

    get plotData$() {
        return this._plotData$.asObservable()
    }

    get plotStatus$() {
        return this._plotState$.asObservable()
    }

    get plotRevision$() {
        return this._plotRevision$.asObservable()
    }

    private _updatePlot() {
        const dataToDisplay = this._dataToDisplay$.getValue();
        const dateRange = this._dateRange$.getValue();
        const interval = this._interval$.getValue();
        const tooltipData = this._tooltipData$.getValue();
        const plotType = this._plotType$.getValue();
        
        // initial plotzoom
        this.plotZoomX = dateRange

        let layout = this._layout$.getValue(),
            trace: any[],
            plotState: PlotState;

        // set fixed dateRange in plot
        if (dateRange && interval) {
            layout['xaxis']['range']        = [dateRange.from, dateRange.until]
            layout['xaxis']['nticks']       = interval / 2 > 20 ? 20 : interval / 2
            layout['xaxis']['autorange']    = false
        }
    
        // optionally set fixed ranges
        layout['xaxis']['fixedrange'] = this._fixedRange.x
        layout['yaxis']['fixedrange'] = this._fixedRange.y

        // set kpi plot config attributes
        layout['yaxis']['tickformat'] = this._tickformat
        layout['yaxis']['range'] = this._yaxisRange

        // check if there is any data in the daterange to display
        let isDataToDisplayInDateRange: boolean = false
        if (dateRange && dataToDisplay) {
            isDataToDisplayInDateRange = dataToDisplay.some(x =>
                new Date(x.date.split('T').join(' ')) >= dateRange.from &&
                new Date(x.date.split('T').join(' ')) <= dateRange.until
            );
        }

        // set type specific yAxis label config
        if (plotType == 'kpi-charged-amount') {
            layout['yaxis']['exponentformat'] = 'B'
            layout['yaxis']['ticksuffix'] = 'Wh  '
            layout['yaxis']['automargin'] = true
            layout['margin']['l'] = 'auto'
        }

        if (dataToDisplay && dataToDisplay.length > 0 && isDataToDisplayInDateRange) {
            trace = [{
                ...this._scatterOptions,
                hovertext: tooltipData,
                x: dataToDisplay.map(x => new Date(x.date.split('T').join(' '))),
                y: dataToDisplay.map(x => x.value),
            }]
            plotState = 'ready'
        } else {
            trace = [{
                ...this._scatterOptions,
                x: [dateRange!.from],
                y: [0],
                marker: {
                    color: 'rgba(255, 255, 255, 0)',
                    size: 0
                },
                customdata: {
                    isEmpty: true
                }
            }]
            plotState = 'empty'
        }

        this._plotData$.next(trace)
        this._layout$.next(layout)
        this._plotRevision$.next(this._plotRevision$.getValue() + 1)
        this._plotState$.next(plotState)
    }

    public onDoubleClick() {this._updatePlot()};
}
