import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { StationColumn, overviewRepository } from "../core/stores/overview.repository";
import { combineLatest, map, Observable, ReplaySubject, share, startWith, withLatestFrom } from "rxjs";
import { OverviewCacheService } from "../core/app-services/overview-cache.service";
import { appRepository } from "../core/stores/app.repository";
import { EnumeratedState } from "../core/helpers/state-helper.service";
import { ChargingStationTransformer, ExtendedChargingStation } from "../core/helpers/transform-stations.helper";
import { SuccessRequestResult } from "@ngneat/elf-requests/src/lib/requests-result";
import { TranslateService } from "@ngx-translate/core";

// TODO remove once created via spec file
export type Recommendation = 
    'No issues detected' | 
    'Create Ticket' | 
    'Check Ticket State' | 
    'Check SIM Portal' | 
    'Restart Station' |
    'Out of Order'

@Component({
    selector: "app-overview",
    template: `
        <evc-overview-alerts *evcHasPermissions="'routes.filterSets'"/>

        <evc-overview-notifications/>

        <app-overview-plots
            [stations]="tableData$ | async"
        />
        <app-station-filters
            [availableTableColumns]="allColumns$ | async"
            [selectedTableColumns]="tableColumns$ | async"
            [additionalTitle]="additionalTitle$ | async"
            [updatedAt]="tableDataUpdatedAt$ | async"
        />
        <app-charging-stations-overview
            [tableContents]="tableData$ | async"
            [pagination]="repo.activePageNum$ | async"
            [maxPage]="(repo.stationsMeta$ | async)?.maxPage || 1"
        />
    `,
    styleUrls: ["./overview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverviewComponent {
    // Content of Overview Table
    tableData$: Observable<ExtendedChargingStation[]>;
    // chosen keys for table columns
    tableColumns$: Observable<StationColumn[]> = this.repo.selectedColumns$.pipe(
        withLatestFrom(this.repo.allColumns$),
        map(([ids, cols]) => {
            const idArray: number[] = ids.map((id) => id.id);
            return cols.filter((column) => idArray.indexOf(column.id) > -1)
        })
    );
    // sorted columns from repo
    allColumns$: Observable<StationColumn[]> = this.repo.allColumns$.pipe(
        map((cols) => cols.sort((a, b) => {
            // sorts alphabetically, but keeps strings starting with numbers at the end of the list
            const aStartsWithNum = /^\d/.test(a.name);
            const bStartsWithNum = /^\d/.test(b.name);

            if (aStartsWithNum && bStartsWithNum) {
                return a.name.localeCompare(b.name);
            } else if (aStartsWithNum) {
                return 1;
            } else if (bStartsWithNum) {
                return -1;
            } else {
                return a.name.localeCompare(b.name);
            }
        }))
    )
    // date of last data update
    public tableDataUpdatedAt$: Observable<Date | null>;
    // subline title for station filters bar
    public additionalTitle$: Observable<string | null>;

    private _chargingStationTransformer = inject(ChargingStationTransformer);

    constructor(
        public repo: overviewRepository,
        public appRepo: appRepository,
        public overviewCacheService: OverviewCacheService,
        private _translate: TranslateService
    ) {
        // get cached results from service, modify to display in table
        this.tableData$ = this.repo.stations$.pipe(
            map(({data}) => {
                const exStations = (data || []).map((station) => {
                    let extStation = this._chargingStationTransformer.extendedChargingStationTransformer(station);

                    // TODO remove generation of recommendations once they're provided by the backend
                    extStation.recommendation = this.generateRandomRecommendation(                        
                        extStation.featuredConnector.lastOverallState, 
                        extStation.stationId
                    );

                    return extStation
                })  
                
                return exStations
            }),
            share({connector: () => new ReplaySubject(1)})
        );

        this.tableDataUpdatedAt$ = this.repo.stations$.pipe(
            map((req) => {
                const updatedAt = (req as SuccessRequestResult).dataUpdatedAt;
                return updatedAt ? new Date(updatedAt) : null;
            })
        )
        
        /**
         * create additional title for station filters bar
         * As allColumns will be updated on language change, we can assume that the new translations are loaded
         * and dismiss an explicit subscription to the langChange event of the translations Service
         */
        this.additionalTitle$ = combineLatest({
            mapTableSync: this.repo.mapTableSync$, 
            searchColumns: this.repo.searchColumns$,
            allColumns: this.repo.allColumns$
        }).pipe(
            map(({mapTableSync, searchColumns, allColumns}) => {
                let textItems = [];

                if (mapTableSync) textItems.push(this._t('DASHBOARD.SYNCHRONISED_WITH_MAP'));
                if (searchColumns && searchColumns.length > 0) {
                    const columnNames = searchColumns.map((searchCol) => {
                        const match = allColumns.find((col) => col.id == searchCol.id)?.name;
                        return `"${match}"` ?? ''
                    });
                    const lastItem = columnNames.pop();
                    const nameListString = columnNames.join(', ') + (columnNames.length ? ` ${this._t('DASHBOARD.SEARCH_IN_COLUMN.AND')} ` : '') + lastItem;
                    textItems.push(this._t(searchColumns.length === 1
                        ? 'DASHBOARD.SEARCH_IN_COLUMN.ONE'
                        : 'DASHBOARD.SEARCH_IN_COLUMN.OTHER',
                        { content: nameListString }
                    ));
                }
                
                return textItems.length > 0 ? textItems.join(` ${this._t('DASHBOARD.SEARCH_IN_COLUMN.AND')} `) : null;
            })
        );
    }

    private _t(path: string, interpolateParams?: Object): string {
        return this._translate.instant(path, interpolateParams)
    }

    // TODO remove once recommendations are provided by backend and random generation is no longer needed
    generateRandomRecommendation(overallState: EnumeratedState | null, stationId: string): Recommendation | null {

        // return no data if account is not the demo one
        if (this.appRepo.getSelectedCustomer()?.identifier != 'demo')  return null;

        // special case station "DE_DEMO_0051"
        if (stationId == "DE_DEMO_0051") {
            return null;
        }

        if (stationId == "DE_DEMO_0054") {
            return null;
        }

        if (stationId == "DE_DEMO_0055") {
            return "Check SIM Portal";
        }

        if (stationId == "DE_DEMO_0049") {
            return "Check Ticket State";
        }

        // 'Create Ticket' if state is failure otherwise no rec
        if (overallState && overallState == 'Failure') {
            return 'Create Ticket';
        } else return null;
    }
}
