import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { Subject, switchMap, takeUntil, tap } from 'rxjs';
import { appRepository } from '../stores/app.repository';
import { defaultVariables } from '../customer-variables/default-customer-variables';

@Component({
    selector: 'app-customer-variables',
    template: '',
    encapsulation: ViewEncapsulation.None
})
export class CustomerVariablesComponent implements OnDestroy {
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private renderer: Renderer2, 
        private _appRepo: appRepository,
    ) {
        // load the stored variables
        this._appRepo.selectedCustomerId$.pipe(
            switchMap((customer) => {
                return this._loadCustomerVariables(customer)
            }),
            tap(variables => {

                // create styles string
                let style: string = `
                --navbar-primary: ${variables.navbarPrimary};
                --navbar-secondary: ${variables.navbarSecondary};
                --navbar-secondary-active: ${variables.navbarSecondaryActive};
                --navbar-secondary-dark: ${variables.navbarSecondaryDark};
                --navbar-tertiary: ${variables.navbarTertiary};
                --kpi-primary: ${variables.kpiPrimary};
                --kpi-secondary: ${variables.kpiSecondary};
                --kpi-text: ${variables.kpiText};
                --kpi-text-15: ${variables.kpiText15};
                --kpi-icon: ${variables.kpiIcon};
                --primary: ${variables.primary}; 
                --primary-active: ${variables.primaryActive};
                --primary-75: ${variables.primary75};
                --primary-50: ${variables.primary50};
                --primary-25: ${variables.primary25};
                --primary-15: ${variables.primary15};
                --primary-10: ${variables.primary10};
                --primary-dark: ${variables.primaryDark};
                --secondary: ${variables.secondary}; 
                --secondary-active: ${variables.secondaryActive};
                --secondary-75: ${variables.secondary75};
                --secondary-50: ${variables.secondary50};
                --secondary-25: ${variables.secondary25};
                --secondary-dark: ${variables.secondaryDark};
                --tertiary: ${variables.tertiary}; 
                --tertiary-active: ${variables.tertiaryActive};
                --tertiary-75: ${variables.tertiary75};
                --tertiary-50: ${variables.tertiary50};
                --tertiary-25: ${variables.tertiary25};
                --tertiary-dark: ${variables.tertiaryDark};
                --state-ok: ${variables.stateOk};
                --state-to-be-monitored: ${variables.stateToBeMonitored};
                --state-potential-failure: ${variables.statePotentialFailure};
                --state-failure: ${variables.stateFailure};
                --state-no-data: ${variables.stateNoData};
                --background: ${variables.background};
                --background-50: ${variables.background50};
                --text: ${variables.text}; 
                --text-active: ${variables.textActive};
                --text-75: ${variables.text75};
                --text-50: ${variables.text50};
                --text-25: ${variables.text25};
                --text-accent: ${variables.textAccent};
                --icon: ${variables.icon};
                --icon-active: ${variables.iconActive};
                --collapsible: ${variables.collapsible};
                --collapsible-90: ${variables.collapsible}E5;
                --collapsed: ${variables.collapsed};
                --collapsed-active: ${variables.collapsedActive};
                --timeline-session-labels: ${variables.timelineSessionLabels};
                --timeline-session-blocks: ${variables.timelineSessionBlocks};
                --timeline-text: ${variables.timelineText};
                --timeline-selection: ${variables.timelineSelection};
                --accent: ${variables.accent};
                --accent--dark: ${variables.accentDark};
                `;

                // render styles
                const styleElement = this.renderer.createElement('style');
                const textNode = this.renderer.createText(':root {' + style + '}');
                this.renderer.appendChild(styleElement, textNode);
                this.renderer.appendChild(document.head, styleElement);

            }),
            takeUntil(this._destroying$)
        ).subscribe()
    }

    private async _loadCustomerVariables(value: string | null) {
        // TODO backend will take over that task
        return defaultVariables;
    }

    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }
}
