import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, HostListener, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject, catchError, combineLatest, interval, of, startWith, take, tap } from 'rxjs';
import { NotificationService } from 'src/app/core/app-services';
import { NotificationDataService } from 'src/app/core/data-backend/data-services';
import { Notification } from 'src/app/core/data-backend/models';
import { decodeString } from 'src/app/core/helpers/utils.helper';
import { overviewRepository } from 'src/app/core/stores/overview.repository';

export type NotificationState = {
    id: string,
    minimized: boolean,
    closed: boolean
}

@Component({
    selector: 'evc-overview-notifications',
    template: `
        @if((displayData$ | async); as notifications) {
            @if((loadingState$ | async) == 'success' && notifications.length > 0) {
                <div class="container notification-wrapper">
                    <div
                        #notificationContainer
                        class="notification-container"
                    >
                        <div class="notification-slider">
                            @for(notification of notifications; track notification.value.notificationId) {
                                <div
                                    class="notification-card"
                                    [class]="notification.value.category+'-card'"
                                    [class.minimized]="notification.state.minimized"
                                    @inOutAnimation
                                    [@toggleMinimize]="{
                                        value: notification.state.minimized ? 'minimized' : 'default',
                                        params: { 
                                            minimized_width: titleRow.offsetWidth + 33 + 'px',
                                            minimized_height: titleRow.offsetHeight + 33 + 'px'
                                        }
                                    }"
                                >
                                    <div class="notification-content">
                                        <div
                                            #titleRow
                                            class="title-row"
                                            (click)="overviewRepo.setNotificationState({
                                                id: notification.value.notificationId,
                                                minimized: !notification.state.minimized,
                                                closed: false
                                            })"
                                        >
                                            <div class="material-icon">
                                                {{ iconMapping[notification.value.category] }}
                                            </div>
                                            <div class="title">{{ notification.value.title }}</div>
                                        </div>
                                        <div
                                            class="description"
                                            [innerHTML]="notification.value.description"
                                        ></div>
                                        @if(!notification.value.persistent) {
                                            <button
                                                class="close-btn"
                                                (click)="overviewRepo.setNotificationState({
                                                    id: notification.value.notificationId,
                                                    minimized: false,
                                                    closed: !notification.state.closed
                                                })"
                                            >{{ 'COMMON.CLOSE' | translate }}</button>
                                        }
                                    </div>
                                </div>
                            }
                        </div> 
                    </div>
                </div>       
            }
        }
    `,
    styleUrl: './overview-notifications.component.scss',
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('inOutAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(-50px)' }),
                animate('.3s ease-out',
                    style({ opacity: 1, transform: 'translateY(0px)' }))
            ]),
            transition(':leave', [
                style({ opacity: 1, width: '*' }),
                animate('.3s ease-in',
                    style({ opacity: 0, width: '0px' }))
            ])
        ]),
        trigger('toggleMinimize', [
            state('minimized', style({
                height: '{{minimized_height}}',
                width: '{{minimized_width}}',
            }), {
                params: {
                    minimized_width: '150px',
                    minimized_height: '57px'
                }
            }),
            state('default', style({
                height: '*',
                width: '*',
            })),
            transition('minimized <=> default', [
                animate('.5s ease-in-out')
            ]),
        ]),
    ]
})
export class OverviewNotificationsComponent {
    @ViewChild('notificationContainer') notificationContainer!: ElementRef;
    // state of receiving data
    public loadingState$ = new BehaviorSubject<'loading' | 'success' | 'error'>('loading');
    // notifications fetched from the backend
    public notifications$ = new BehaviorSubject<Notification[]>([]);
    // displayable notifications and their state
    public displayData$ = new BehaviorSubject<{value: Notification, state: NotificationState}[]>([]);
    // notification icon based on category
    public iconMapping = {
        update: 'new_releases',
        information: 'campaign',
        warning: 'warning',
        error: 'error'
    }
    // regex to detect urls
    private _urlRegex = /\b(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/\S*)?\b/;

    private _notificationService: NotificationService = inject(NotificationService);

    constructor(
        public overviewRepo: overviewRepository,
        private _notificationDataService: NotificationDataService
    ) {
        this.loadingState$.next('loading');
        this._notificationDataService.getNotifications().pipe(
            take(1),
            tap((notifications) => {
                this.loadingState$.next('success');
                // check for urls in notification - turn them into a link
                notifications = notifications.map(notification => {
                    notification.title = decodeString(notification.title);

                    notification.description = notification.description.replace(this._urlRegex, (url) => {
                        return `<a href="https://${url}" target="_blank">${url}</a>`;
                    });

                    return notification
                });
                // sort notifications by recency of from date
                notifications.sort((a, b) => {
                    let dateA = new Date(a.from_date);
                    let dateB = new Date(b.from_date);
                    return dateB.getTime() - dateA.getTime();
                });
                // fill notifications
                this.notifications$.next(notifications);
            }),
            catchError((_) => {
                this.loadingState$.next('error');
                this._notificationService.showError('Error notifications could not be retrieved');
                return of([]);
            })
        ).subscribe();

        combineLatest([
            this.notifications$,
            this.overviewRepo.notificationStates$,
            interval(10 * 60 * 1000).pipe(startWith(0)),
        ]).pipe(
            takeUntilDestroyed(),
            tap(([notifications, states]) => {
                // remove notifications that should not be shown
                // - 'from' date is after now || 'to' date is set and before now
                let now = new Date();
                let notificationsInRange = notifications.filter(notification => {
                    let fromDate = new Date(notification.from_date);
                    let toDate = notification.to_date ? new Date(notification.to_date) : undefined;
                    if (fromDate > now) return false;
                    if (toDate && toDate < now) return false;
                    return true;
                });
                this.displayData$.next(notificationsInRange.map(notification => {
                    // find corresponding state for notification
                    let state = states.find(state => state.id === notification.notificationId);
                    // if persistent but closed update state to open
                    if (notification.persistent && state?.closed) {
                        state.closed = false;
                        this.overviewRepo.setNotificationState({
                            id: notification.notificationId,
                            minimized: state.minimized,
                            closed: false
                        })
                    }
                    // return object with notification and state - new empty state if none was found
                    return {
                        value: notification,
                        state: state || 
                            {
                                id: notification.notificationId,
                                minimized: false,
                                closed: false
                            }
                        };
                }).filter(combined => {
                    // filter out previously closed notifications
                    return !combined.state.closed;
                }));
            })
        ).subscribe();
    }

    @HostListener('wheel', ['$event'])
    onWheel(event: WheelEvent): void {
        if (event.deltaY !== 0 && !event.shiftKey) {
            const container = this.notificationContainer.nativeElement;
            const threshholdUp = 0;
            const threshholdDown = container.scrollWidth - container.offsetWidth;
    
            if ((event.deltaY < 0 && container.scrollLeft !== threshholdUp) ||
                (event.deltaY > 0 && container.scrollLeft !== threshholdDown)) {
                container.scrollLeft += event.deltaY;
                event.preventDefault();
            }
        }
    }
}
